.page-core {
    margin: 50px 30px;
    display: flex;
    flex-direction: column;
}

/* ------------------------ */

.body-container {
    display: flex;
    flex-direction: row;
}

.left-container {
    flex: 1;
}

.profile-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-picture {
    width: 50%;
    border-radius: 20px;
}

/* ------------------------ */

.right-container {
    flex: 1;
}
.description-container {
    font-size: 18px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
}

.description-paragraph {
    font-size: 20px;
    font-weight: 800;
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .page-core {
        margin: 50px 0;
        display: flex;
        flex-direction: column;
    }

    .body-container {
        display: flex;
        flex-direction: column;
    }

    .profile-picture {
        width: 100%;
        border-radius: 20px;
    }

    .description-paragraph {
        margin-top: 40px;
    }
}
