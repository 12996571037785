.buffer-container {
    margin: 30px 0px;
    height: 300px;
    width: 100%;
}

.buffer-image {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    object-position: bottom;
}
