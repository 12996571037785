.header-container {
    padding: 0px 30px;
    width: 100%;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: column;
}

.title-name {
    font-size: 40px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
}

.subtitle {
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 40px;
    font-weight: 800;
}

.subtitle-list-item-container {
    display: none;
}

@media only screen and (max-width: 600px) {
    .header-container {
        padding: 0px 30px;
        width: 100%;
        border-bottom: 1px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title-name {
        font-size: 40px;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        text-align: center;
    }

    .subtitle {
        display: none;
    }

    .subtitle-list-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .subtitle-list-item {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }
}
