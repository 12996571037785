.social-media-header-container {
    width: 100%;
    padding: 20px 0px;
    border-bottom: 1px solid black;
    text-align: center;
    margin-bottom: 30px;
}

.social-media-header {
    font-size: 30px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 800;
}

.options {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

.option-container {
    flex: 1;
    margin: 10px;
    height: 100px;
    opacity: 0.9;
}

.option-container:hover {
    opacity: 0.5;
    cursor: pointer;
}

.option {
    color: rgb(255, 255, 255);
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 30px;
}

.github {
    border-radius: 20px;
    background-color: black;
}

.linkedin {
    border-radius: 20px;
    background-color: #000000;
}

.twitter {
    border-radius: 20px;
    background-color: #000000;
}

.instagram {
    border-radius: 20px;
    background-color: #000000;
}

@media only screen and (max-width: 600px) {
    .options {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .option-container {
        margin: 10px;
        height: 100px;
        position: relative;
        opacity: 0.9;
        padding: 10px;
        width: 100%;
    }

    .option-container:hover {
        opacity: 0.5;
        cursor: pointer;
    }

    .option {
        color: rgb(255, 255, 255);
        font-family: 'Work Sans', sans-serif;
        font-weight: bold;
        font-size: 20px;
    }
}
